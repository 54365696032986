import { useState } from "react";
import { storage } from "../firebase";
import { ref, uploadBytes, getDownloadURL ,deleteObject} from "firebase/storage";
import { v4 as uuidv4 } from "uuid"; 

const useImageUploadThumnail = () => {
  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [error, setError] = useState(null);

  const uploadImage = async (file, username) => {
    setUploading(true);
    setError(null);

    try {
      const uniqueName = `${uuidv4()}thumbnail_${file.name}`;
      const storageRef = ref(storage, `/${username}/${uniqueName}`);
      const snapshot = await uploadBytes(storageRef, file, {
        contentType: file.type // Ensure the MIME type is preserved
      });      
      const downloadURL = await getDownloadURL(snapshot.ref);
      console.log('download url', downloadURL)

      setUploading(false);
      return downloadURL;
    } catch (err) {
      setError(err);
      console.log('upload error', err)
      setUploading(false);
      return null;
    }
  };

  
  const deleteImage = async (url) => {
    setDeleting(true);
    setError(null);

    try {
      // Extract the file path from the URL
      const decodedUrl = decodeURIComponent(url);
      const baseUrl = "https://firebasestorage.googleapis.com/v0/b/";
      const filePath = decodedUrl.substring(decodedUrl.indexOf("/o/") + 3, decodedUrl.indexOf("?alt=media"));
      const storageRef = ref(storage, filePath);
      
      await deleteObject(storageRef);
      console.log('File deleted successfully');

      setDeleting(false);
      return true;
    } catch (err) {
      setError(err);
      console.log('delete error', err);
      setDeleting(false);
      return false;
    }
  }

  return { uploadImage, uploading, error,deleteImage };
};

export default useImageUploadThumnail;
