import React from "react";
import useMyTriviaList from "../customHooks/useMyTrivaList";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Spin, message, Badge, Pagination } from "antd";
import { mains } from "./constants/routes";
import event from "../customHooks/useGoogleAnalyticsTrigger";

export default function MyTrivia({ onChange }) {
  const [messageApi, contextHolder] = message.useMessage();
  const {
    myTriviaQuestions,
    isLoading,
    currentPage,
    totalPages,
    setCurrentPage,
    setTotalPages,
  } = useMyTriviaList();

  const onChangePage = (page) => {
    setCurrentPage(page);
    console.log(totalPages, currentPage)
  };

  const deleteUploadLookupToQuestion = async (qustionToUpload) => {
    let main = await mains()
    event({
      action: 'Delete',
      category: 'Delete',
      label: `Delete`,
      value: qustionToUpload.element_id,
    });
    const url = main.API_USER_ELEMENT_GAME_DATA+`/trivia-question-list/elements/${qustionToUpload.element_id}`;

    const options = {
      method: "DELETE",
      headers: {
        "api-key": main.API_KEY,
        Accept: "application/json",
        Authorization:
          "Bearer fMnmHgaqcvs524DKhYGxuCbZWw7NXByLR6Fzt3TAEQdr9pkjPJ",
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data?.success) {
        window.location.reload();
        onChange("");
      } else {
        messageApi.info(`${data.message} `);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col mx-10 my-5 items-center min-h-[80vh]">
      <h1 className="text-white text-xl font-medium ">My Trivia List</h1>
      {isLoading ? (
        <div className="flex flex-col mt-20 w-[50vw]">
          <Spin size={50} />
        </div>
      ) : (
        <div className="flex flex-col w-[50vw]">
          {myTriviaQuestions.map((item, index) => (
            <Badge.Ribbon
              placement="start"
              text={
                item?.data?.tags ? (
                  <div>
                    {item?.data.tags.map((item, index) => {
                      if (["type_choice", "type_feud", "type_quize"].includes(item)) {
                        return <div className="text-sm">{item}</div>;
                      }
                    })}
                  </div>
                ) : null
              }
              color="#3f5d3f"
            >
              <div className="flex flex-row  justify-between items-center py-1 text-white bg-[#414550] text-xl px-2 mt-6 border-b-2 rounded-md">
                <div>
                  <h1 className="mr-2 text-sm uppercase">{item.data.name}</h1>
                  <h1 className="text-xs">{item.data.dataId}</h1>
                </div>
                <div className="w-20  right-[45vw]">
                  <div className="flex flex-row justify-between w-[100%] h-[100%]">
                    <a
                      onClick={() => onChange(item)}
                      className="cursor-pointer hover:bg-[black] px-2"
                    >
                      <EditOutlined />
                    </a>
                    <a
                      onClick={() => deleteUploadLookupToQuestion(item)}
                      className="cursor-pointer hover:bg-[black] px-2"
                    >
                      <DeleteOutlined />
                    </a>
                  </div>
                </div>
              </div>
              <br />
            </Badge.Ribbon>
          ))}
          {/* {myTriviaQuestions.length > 0 ? (
            <div className="bg-[#ffffff6a] px-2 py-2 rounded-md">
              <Pagination
                current={currentPage}
                pageSize={2}
                onChange={onChangePage}
                total={totalPages}
              />
            </div>
          ) : (
            <h1 className="text-center text-[#5e5d5d] mt-20 text-4xl">You Have No Data</h1>
          )} */}
        </div>
      )}
    </div>
  );
}
